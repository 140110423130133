import React from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { v4 } from "uuid";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Button } from "@material-ui/core";
import coverPhoto from "../images/whitepaper-cover.png";
import SEO from "./seo";

export const USA_STATES = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Minor Outlying Islands",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "U.S. Virgin Islands",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export default class RequestWhitePaperForm extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.scrollToMyRef = this.scrollToMyRef.bind(this);
    this.state = {
      location: "",
      name: "",
      email: "",
      company: "",
      phone: "",
      comment: "",
    };
  }

  scrollToMyRef() {
    this.node.scrollIntoView();
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, email, company, location, phone, comment } = this.state;
    const data = {
      name,
      email,
      company,
      location,
      phone,
      note: comment,
    };
    axios.put(
      `https://lablog-62234.firebaseio.com/request-whitepaper/${v4()}.json`,
      { data }
    );

    navigate("/whitepaper-form-submitted/", {
      state: { data },
    });
  }

  render() {
    return (
      <>
        <SEO
          title="STRATEGIES FOR LIFE SCIENCES COMPANIES USING LABLOG WITH GXP SYSTEMS"
          description="Analyze the data security requirements of Title 21 CFR Part 11 and explore how to meet those requirements by using LabLog."
        />

        <Container maxWidth="md" style={{ paddingTop: "50px" }}>
          <Typography component="div">
            <Box
              textAlign="center"
              component="h1"
              m={1}
              fontFamily="DM Serif Text"
              fontWeight="700"
              color="#3d3d3d"
              fontSize="h2.fontSize"
            >
              STRATEGIES FOR LIFE SCIENCES COMPANIES USING LABLOG WITH GxP
              SYSTEMS
            </Box>
            <br />
            <Container maxWidth="sm">
              <Box color="#3d3d3d" component="p" textAlign="center">
                Analyze the data security requirements of Title 21 CFR Part 11
                and explore how to meet those requirements by using LabLog.
              </Box>
            </Container>
          </Typography>
          <form onSubmit={this.handleSubmit}>
            <Container maxWidth="md">
              <Grid container spacing={3}>
                <Grid item md>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="name-input">Name</InputLabel>
                    <Input
                      id="name-input"
                      variant="filled"
                      required
                      value={this.state.name}
                      onChange={(e) => this.setState({ name: e.target.value })}
                      aria-describedby="my-helper-text"
                    />
                  </FormControl>
                </Grid>
                <Grid item md>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="company-input">
                      Company/Institute
                    </InputLabel>
                    <Input
                      id="company-input"
                      value={this.state.company}
                      onChange={(e) =>
                        this.setState({ company: e.target.value })
                      }
                      aria-describedby="my-helper-text"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="phone-input">Phone</InputLabel>
                    <Input
                      id="phone-input"
                      value={this.state.phone}
                      onChange={(e) => this.setState({ phone: e.target.value })}
                      aria-describedby="my-helper-text"
                    />
                  </FormControl>
                </Grid>
                <Grid item md>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="email-input">Email address</InputLabel>
                    <Input
                      id="email-input"
                      required
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      aria-describedby="my-helper-text"
                    />
                    <FormHelperText id="my-helper-text">
                      We Respect Your Privacy.
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item md>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="location-simple">Location</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      value={this.state.location}
                      onChange={(e) =>
                        this.setState({ location: e.target.value })
                      }
                      inputProps={{
                        name: "location",
                        id: "location-simple",
                      }}
                    >
                      {USA_STATES.map((stateName) => (
                        <MenuItem value={stateName} key={stateName}>
                          {stateName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md />
              </Grid>
              <br />
            </Container>
            <br />
            <br />
            <br />
            <Container maxWidth="xs">
              <Button
                variant="contained"
                type="submit"
                color="secondary"
                fullWidth
              >
                Download for Free
              </Button>
            </Container>
          </form>

          <br />
          <br />
          <Grid
            container
            spacing={3}
            style={{ marginTop: "50px", backgroundColor: "#fff" }}
          >
            <Grid item md style={{ backgroundColor: "#fff" }}>
              <img alt="Cover" src={coverPhoto} style={{ width: "100%" }} />
            </Grid>
            <Grid item md>
              <Box
                textAlign="left"
                component="h2"
                m={1}
                fontFamily="DM Serif Text"
                fontWeight="700"
                color="#3d3d3d"
                fontSize="h3.fontSize"
              >
                In this whitepaper you will learn:
              </Box>
              <Box color="#3d3d3d" component="p" textAlign="left">
                &#8226;&nbsp;How LabLog guarantees data security, resilience,
                and availability
                <br />
                <br />
                &#8226;&nbsp;How we leverage Microsoft services and external
                audits to perform independent tests against accepted
                international standards for cybersecurity and service trust
                including Title 21 CFR Part 11
                <br />
                <br />
                &#8226;&nbsp;How to analyze the data security requirements of
                Title 21 CFR Part 11
                <br />
                <br />
                &#8226;&nbsp;How LabLog can meet those requirements
                <br />
                <br />
                &#8226;&nbsp;How to define the levels of shared responsibilities
                for compliance to Title 21 CFR Part 11, when adopting LabLog
                <br />
                <br />
              </Box>
            </Grid>
          </Grid>
          <br />
          <br />
          <br />
          <Box
            textAlign="left"
            component="h2"
            m={1}
            fontFamily="DM Serif Text"
            fontWeight="700"
            color="#3d3d3d"
            fontSize="h3.fontSize"
          >
            About the whitepaper
          </Box>
          <Box color="#3d3d3d" component="p" textAlign="left">
            LabLog’s mission is to protect your data and make sure that it’s
            always available to you. Our philosophy necessitates data security,
            resilience, and availability. In this document you will learn how we
            technically achieve these expectations and how we leverage Microsoft
            services and external audits to perform independent tests against
            accepted international standards for cybersecurity and service trust
            including Title 21 CFR Part 11.
            <br />
          </Box>
          <br />
          <br />
          <br />
          <Box
            textAlign="left"
            component="h2"
            m={1}
            fontFamily="DM Serif Text"
            fontWeight="700"
            color="#3d3d3d"
            fontSize="h3.fontSize"
          >
            About the authors
          </Box>
          <Box color="#3d3d3d" component="p" textAlign="left">
            LabLog is the heart of the modern lab record keeping, simplifying
            note-taking and FDA compliance. The company’s comprehensive
            application delivery platform combines note taking, data analytics,
            project management, audit trails, and inventory management in one
            easy-to-use software package.
            <br />
          </Box>
          <br />
          <br />
          <Button
            onClick={(e) => this.scrollToMyRef(e)}
            variant="contained"
            color="secondary"
            fullWidth={false}
          >
            Download for Free
          </Button>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </>
    );
  }
}
